.chains{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.chain__list{
    border: 1px solid rgb(18, 184, 18);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    font-size: 10px;
    color: rgb(18, 184, 18);
}

section{
    max-width: 600px;
    margin: auto;
    
}

label{
    font-size: 16px;
    color: #fff;
  }

.vault__container{
    margin: auto;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.vault__item {
    margin-top: 10px;
    border: 1px solid #15171c;
    border-radius: 5px;
    padding: 10px;
    font-size: 10px;
    color: #fff;
    background: linear-gradient(90deg, #15171c, #8484f1 58.85%, #15171c);
    
}
