:root {
  --primary: #fff;
  --secondary: #fff;
  --background-fade: rgba(0, 0, 0, 0.5);
}

p {
  font-size: 16px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  color: inherit;
}

input:focus {
  border: 1px solid red;
}

.navbar ul li a:hover {
  color: #fff;
}

a:active {
  color: inherit;
}

label{
  font-size: 16px;
  color: #fff;
}

.navbar ul li {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.nav-brand img {
  width: 100px;
}

.hamburger {
  display: none;
  padding: 5px;
}

.nav-menu .elected{
  padding: 8px;
  cursor: pointer;
}

.nav-menu .elected i{
  display: none;
}
.nav-menu .elected:hover{
  color: #000;
  border-bottom: 2px solid #fff;
  border-radius: 10px;

}

.bar {
  display: block;
  width: 20px;
  height: 3px;
  margin: 2px auto;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.nav-item {
  margin-left: 2rem;
  font-family: "Roboto";
}


@media only screen and (max-width: 768px) {
  .nav-brand img {
    width: 70px;
  }

  .nav-menu {
    position: fixed;
    top: 0;
    left: -100%;
    flex-direction: column;
    background-color: #0e0e0e;
    color: #fff;
    height: 100%;
    width: 75%;
    border-radius: 0 10px 10px 0;
    text-align: start;
    transition: 0.3s ease;
    padding-top: 4.5rem;
    z-index: 10;
  }
  .nav-menu {
    display: flex;
    align-items: start;
    justify-content: start;
  }
  .nav-menu .elected:hover{
    color: #fff;
    border-bottom:none;
    border-radius: none;
  
  }
  
  .nav-menu .elected{
    display: flex;
    border: none;
    border-radius: 0;
    padding: 10px;
    cursor: pointer;
  
  }

  .nav-menu .elected i{
    display: block;
    margin-right: 5px;
    margin-top: 3px;
    color: #fff;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 0;
    padding: 1.5rem 0;
    text-align: center;
    border: none; 
  }

  .nav-item:last-child {
    border-bottom: none; 
  }

  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 20;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-5px) rotate(-45deg);
  }

  .background-fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background-fade);
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .background-fade.active {
    opacity: 1;
    visibility: visible;
  }
}
